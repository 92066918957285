<template>
  <div :class="`${bigHeading ? 'md-flex' : 'flex'} bg-color-white overflow-hidden col-12`" style="border-radius: 12px">
    <div :class="`relative ${bigHeading ? 'blog-item-big-img md-col-6 col-12' : 'blog-item-blog-img col-6'}`">
      <div class="absolute col-12 h100 cover bg-color-12 skeleton"></div>
    </div>
    <div :class="`md-col-8 col-12 border-box blog-item-blog-img ${bigHeading ? 'big-card-padding' : 'blog-card-padding'}`"></div>
  </div>
</template>

<script>
export default {
  name: "blogItemSkeleton",
  props: {
    bigHeading: Boolean,
  },
};
</script>

<style scoped>
.blog-item-big-img {
  min-height: 250px;
}
.blog-item-blog-img {
  min-height: 220px;
}

@media (max-width: 1024px) {
  .blog-item-blog-img {
    min-height: 128px;
  }
}
</style>
