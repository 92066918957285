<template>
  <a v-if="blogItem.feature_image != null" :href="blogPostLink" class="md-flex bg-color-white overflow-hidden block blog-item-card rsf-card-interaction-animation" style="border-radius: 12px">
    <div class="relative small-blog-item-img md-col-4 col-12 bg-color-12">
      <img v-if="blogItem.feature_image != null" class="absolute col-12 h100 cover blog-feature-image" onload="this.style.opacity=1" :src="blogItem.feature_image.image_path" loading="lazy" />
    </div>
    <div class="md-col-8 col-12 px2 border-box small-card-padding">
      <div @click.stop.prevent="updateActiveCategory(blogItem.blog_categories[0])" class="flex items-center gap1 pointer blog-item-category-tag">
        <img :src="`https://blog.apps.redseafish.com/static-assets/${blogItem.blog_categories[0].term_slug}-b.svg`" loading="lazy" style="width: 20px" />
        <span class="open-sans-bold color-1 h6" v-html="blogItem.blog_categories[0].term_name"></span>
      </div>
      <h3 class="helvetica-bold md-h4 h5 color-black mt1" v-html="blogItem.post_title"></h3>
    </div>
  </a>
</template>

<script>
export default {
  name: "blogItemSmall",
  props: {
    blogItem: Object,
    updateActiveCategory: Function,
  },
  computed: {
    blogPostLink() {
      return this.blogItem.seo_url.replace("/blog_item/", "/article/");
    },
  },
};
</script>

<style scoped>
.small-blog-item-img {
  min-height: 142px;
  min-width: 138px;
}
.blog-feature-image {
  opacity: 0;
  transition: 0.3s ease;
}

.blog-item-card {
  transition: 0.3s;
}

@media (hover: hover) and (pointer: fine) {
  .blog-item-card:hover h3 {
    color: var(--rsf-2022-color-1);
  }
  .blog-item-category-tag:hover span {
    text-decoration: underline;
  }
}

@media (max-width: 52em) {
  .small-blog-item-img {
    min-height: 150px;
  }
}
</style>
