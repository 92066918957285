<template>
  <a v-if="blogItem.feature_image != null" :href="blogPostLink" :class="`${bigHeading ? 'md-flex block' : 'flex'} bg-color-white overflow-hidden blog-item-card rsf-card-interaction-animation`" style="border-radius: 12px">
    <div :class="`relative bg-color-12 ${bigHeading ? 'blog-item-big-img md-col-6 col-12' : 'blog-item-blog-img col-6'}`">
      <img v-if="blogItem.feature_image != null" class="absolute col-12 h100 cover blog-feature-image" onload="this.style.opacity=1" :src="blogItem.feature_image.image_path" loading="lazy" />
    </div>
    <div :class="`md-col-8 col-12 border-box ${bigHeading ? 'big-card-padding' : 'blog-card-padding'}`">
      <div class="flex items-center flex-wrap gap1">
        <div :class="`flex items-center gap1 pointer blog-item-category-tag ${index > 0 ? 'md-flex hide' : ''}`" v-on:click.stop.prevent="updateActiveCategory(category)" v-for="(category, index) in blogItem.blog_categories" :key="index">
          <img :src="`https://blog.apps.redseafish.com/static-assets/${category.term_slug}-b.svg`" loading="lazy" style="width: 20px" />
          <span class="open-sans-bold color-1 h6" v-html="category.term_name"></span>
        </div>
      </div>
      <h3 :class="`helvetica-bold ${bigHeading ? 'h1' : 'md-h2 h4'} color-black mt2 md-mb1 mb0`" v-html="blogItem.post_title"></h3>
      <p class="open-sans-regular h5 color-black md-block hide" v-html="blogItem.post_excerpt"></p>
    </div>
  </a>
</template>

<script>
export default {
  name: "blogItem",
  props: {
    blogItem: Object,
    updateActiveCategory: Function,
    bigHeading: Boolean,
  },
  computed: {
    blogPostLink() {
      return this.blogItem.seo_url.replace("/blog_item/", "/article/");
    },
  },
};
</script>

<style scoped>
.blog-item-big-img {
  min-height: 250px;
}
.blog-item-blog-img {
  min-height: 220px;
}

.blog-item-card {
  transition: 0.3s;
}

@media (hover: hover) and (pointer: fine) {
  .blog-item-card:hover h3 {
    color: var(--rsf-2022-color-1);
  }
  .blog-item-category-tag:hover span {
    text-decoration: underline;
  }
}

.blog-feature-image {
  opacity: 0;
  transition: 0.3s ease;
}
@media (max-width: 1024px) {
  .blog-item-blog-img {
    min-height: 128px;
  }
}
</style>
